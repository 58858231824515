const toggleSubMenu = function () {
  $("*[data-dropdown-items-desktop]").slideToggle("fast");
  $("*[data-change-svg-desktop]").toggleClass(
    "side-menu-arrow side-menu-arrow-clicked"
  );
};
const closeSubMenu = (element) => {
  $("*[data-dropdown-items-desktop]").slideToggle("fast", false);
  $("*[data-change-svg-desktop]").toggleClass(
    "side-menu-arrow side-menu-arrow-clicked",
    false
  );
};
const toggleDropdown = (element) => {
  const parent = element.parentElement;

  $("*[data-dropdown][data-open]")
    .not(element)
    .each(function () {
      toggleDropdown(this);
    });

  parent.querySelectorAll("[data-dropdown-items]").forEach((child) => {
    $(child).slideToggle("fast");
  });

  element.toggleAttribute("data-open");
};

window.addEventListener("load", () => {
  let dropDownBtn = document.querySelectorAll("[data-dropdown]");
  let qrCodeDropdown = document.querySelectorAll("[data-show-qr-code-dropdown]");
  let qrCodeNoAr = document.querySelector("[data-drop-down-qr]")
  let mainContainer = document.querySelector("[data-add-padding]")

  qrCodeDropdown.forEach((dropdownQr) => {
    dropdownQr.addEventListener("click", () => {
      qrCodeNoAr.classList.toggle("open-drop-down-qr");
      mainContainer.classList.toggle("padding-open-white-side-menu")
    })
  })
  if(!dropDownBtn){
    return
  }
  dropDownBtn.forEach((el) => {
    el.addEventListener("click", (e) => {
      console.log("hallo")
      e.preventDefault();
      e.stopPropagation();
      toggleDropdown(el);
    });
  });
  // subdropdown
  $("*[data-dropdown-desktop]").click(toggleSubMenu);

  $(document).on("click", function (event) {
    let $trigger = $("*[data-dropdown][data-open]")
      .siblings()
      .has("*[data-dropdown-items]");

    const clickedOnDropdown = !$trigger.has(event.target).length;

    if (clickedOnDropdown) {
      document
        .querySelectorAll("[data-dropdown][data-open]")
        .forEach(toggleDropdown);
      closeSubMenu();
    }
  });
  // $("*[data-dropdown]").click(function(e) {
  //   e.stopPropagation();
  // });
});


function mobileDownloadDropdown(){
  $("*[data-dropdown='download']").click(function () {
    $("*[data-dropdown-items-download='mobile']").slideToggle("fast");
    $("*[data-change-svg]").toggleClass(
      "side-menu-arrow-clicked side-menu-arrow"
    );
  });
}

document.addEventListener('htmx:afterOnLoad', mobileDownloadDropdown)