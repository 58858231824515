import { activateAR } from "./model-view";

const qr_code = document.getElementById("qr-code");
const show_qr_code = document.querySelectorAll("[data-open-qr-modal]");
const modelViewer = document.querySelector("#model-viewer");
const sideMenuWhite = document.querySelector("[data-side-menu-white]")
const closeSideMenuBtn = document.querySelectorAll("[data-close-side-menu-white]")
const smallLaptopBreakpoint = window.matchMedia("(max-width: 1024px)");

show_qr_code.forEach((open_qr) => {
  open_qr.addEventListener("click", (e) => {
    if (modelViewer.canActivateAR) {
      activateAR();
      return;
    }
    e.stopPropagation();
    openQR();
    closeQR();
  });
  open_qr.addEventListener("click", (e) => {
    if (!modelViewer.canActivateAR && smallLaptopBreakpoint.matches){
      e.preventDefault();
      sideMenuWhite.classList.add("open-side-menu-white");
      return;
    }
  })
});

function checkBrowser(){
  const whiteSideHeadline = document.querySelector("[data-side-menu-white='headline']")
  const whiteSideSubhead = document.querySelector("[data-side-menu-white='subhead']")
  const firefox = navigator.userAgent.indexOf("Firefox")


  if(typeof navigator.brave !== 'undefined' || firefox != -1){
    if(whiteSideHeadline && whiteSideSubhead){
      whiteSideHeadline.innerHTML = "Dein mobiles Endgerät oder Browser ist leider nicht Augmented Reality fähig"
      whiteSideSubhead.innerHTML = "Falls du die Möglichkeit hast ein anderes mobiles Endgerät oder Browser zu verwenden, versuche es erneut"
    }
  }else{
    return
  }

}

checkBrowser() 


closeSideMenuBtn.forEach((closeBtn) => {
  closeBtn.addEventListener("click", () => {
    window.location.reload();
  })
})

function openQR() {
  qr_code.style.display =
    window.getComputedStyle(qr_code, null).getPropertyValue("display") ===
    "none"
      ? "flex"
      : "none";
}

function closeQR() {
  document.addEventListener("mouseup", (e) => {
    if (!qr_code.contains(e.target)) {
      qr_code.style.display = "none";
    }
  });
}
