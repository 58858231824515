
window.addEventListener("load",() => {
  let sideNav = document.getElementById("side-nav");
  let menuOpens = document.querySelectorAll("[data-menu-open]");
  let menusClosed = document.querySelectorAll("[data-menu-close]")
  if(!menuOpens)
    return;
  
  menuOpens.forEach((item) => item.addEventListener("click", openNav));
  menusClosed.forEach((item) => item.addEventListener("click",closeNav));
  function openNav() {
    sideNav.style.right = "0";
    sideNav.style.zIndex = "1001";
  }
  
  function closeNav() {
    sideNav.style.right = "-100%";
    sideNav.style.zIndex = "-1";
  }
})

