let qrMenu = document.querySelectorAll("[data-side-menu-qr]");
let showQrTableDesktop = document.querySelectorAll(
  "[data-side-menu-desktop='qr-side-menu']"
);
let modelSideMenu = document.querySelector("[data-side-menu]");
let modelViewer = document.querySelector("#model-viewer");
let arLoading = document.querySelector(".ar-loading");

window.addEventListener("load", () => {
  ShowSideMenuModelView();
  closeSideMenuQr();
  showSideMenuDesktop();
  closeDesktopSideMenu();
  closeSideMenu();
  activateArByQuery();
  copyToClipboard();
  copyToClipboardHtmx();
});

function ajdustHeight() {
  const container = document.querySelector(".mv-maxwidth-container");
  const sideMenuDesktop = document.querySelector("#side-menu-content");

  if (!container || !sideMenuDesktop) {
    return;
  }

  container.style.minHeight = $(sideMenuDesktop).outerHeight() + "px ";
}
document.addEventListener("htmx:afterOnLoad", ajdustHeight);
window.addEventListener("resize", ajdustHeight);
document.addEventListener("htmx:afterOnLoad", showSideMenuQr);
document.addEventListener("htmx:afterOnLoad", closeDesktopSideMenu);

function activateArByQuery() {
  let modelViewer = document.querySelector("#model-viewer");
  const params = getSearchParams(window.location.search);
  if (params.webar) {
    modelViewer.addEventListener("load", () => {
      activateAR();
    });
  }
}

export function activateAR() {
  arLoading.classList.remove("hide");
  modelViewer.activateAR();
  modelViewer.addEventListener("ar-status", (e) => {
    if (event.detail.status != "not-presenting") {
      arLoading.classList.add("hide");
    }
  });
}


function ShowSideMenuModelView() {
  let sideMenuBtn = document.querySelectorAll("[data-menu-points]");
  sideMenuBtn.forEach((sideMenu_Btn) => {
    sideMenu_Btn.addEventListener("click", (e) => {
      e.preventDefault;
      openSideMenu();
    });
  });
}

function openSideMenu() {
  let modelSideMenu = document.querySelector("[data-side-menu]");
  modelSideMenu.classList.add("open");
  document.querySelector("body").style.overflow = "hidden"
}

function closeSideMenu() {
  let closeSideMenuMv = document.querySelectorAll("[data-close-side-menu-mv]");
  closeSideMenuMv.forEach((closeMvSideMenu) => {
    closeMvSideMenu.addEventListener("click", (e) => {
      e.preventDefault();
      modelSideMenu.classList.remove("open");
      document.querySelector("body").style.overflow = "unset"
    });
  });
}

function showSideMenuQr() {
  let showSideQrBtn = document.querySelectorAll("[data-show-qr-side-menu]");
  showSideQrBtn.forEach((showQR) => {
    showQR.addEventListener("click", (e) => {
      console.log("hallo");
      e.preventDefault;
      openDesktopQr();
    });
  });
}

function openSideMenuQr() {
  qrMenu.forEach((qr_Menu) => {
    qr_Menu.classList.add("open");
  });
}

function closeSideMenuQr() {
  let closeSideMenuBtn = document.querySelectorAll("[data-close-qr-menu]");
  closeSideMenuBtn.forEach((closeQRsideMenu) => {
    closeQRsideMenu.addEventListener("click", (e) => {
      e.preventDefault();
      qrMenu.forEach((qr_Menu) => {
        qr_Menu.classList.remove("open");
      });
    });
  });
}

function showSideMenuDesktop() {
  let showSideMenuQrDesk = document.querySelectorAll(
    "[data-side-menu-desktop='button']"
  );
  showSideMenuQrDesk.forEach((sideMenuQrDesk) => {
    sideMenuQrDesk.addEventListener("click", (e) => {
      console.log("hallo");
      e.preventDefault();
      openDesktopQr();
    });
  });
}

function openDesktopQr() {
  showQrTableDesktop.forEach((showQrTable) => {
    showQrTable.classList.add("open-side-menu");
    document.querySelector("body").style.overflow = "hidden"
  });
}

function closeDesktopSideMenu() {
  let closeSideMenuBtnDesk = document.querySelectorAll(
    "[data-side-menu-desktop='close']"
  );
  closeSideMenuBtnDesk.forEach((closeSideQrDesk) => {
    closeSideQrDesk.addEventListener("click", (e) => {
      e.preventDefault();
      showQrTableDesktop.forEach((showQrTable) => {
        showQrTable.classList.remove("open-side-menu");
        document.querySelector("body").style.overflow = "unset"
      });
    });
  });
}

function getSearchParams(query) {
  query = query.substring(1);
  const result = {};
  query.split("&").forEach((param) => {
    const [key, value] = param.split("=");
    result[key] = value;
  });
  return result;
}

function copyToClipboard(){
  let link = document.querySelectorAll("[data-copy-to-clipboard]");
  link.forEach((links) => {
    // if (navigator.clipboard && window.isSecureContext && window.navigator.userAgent.indexOf("Chrome") !== -1){
    //   links.addEventListener("touchstart", function(event) {
    //     event.preventDefault();
    //     const hrefValue = window.location.origin + links.getAttribute("href");
    //     navigator.clipboard.writeText(hrefValue).then(function(){
    //       var checkmark = document.createElement("span");
    //       checkmark.innerHTML = "&#10003;";
    //       checkmark.style.paddingLeft = "10px"
    //       links.appendChild(checkmark);
    //       setTimeout(function(){
    //         links.removeChild(checkmark);
    //       }, 2000);
    //     }, function(){
    //       alert("Das Kopieren in die Zwischenablage wird von Ihrem Browser nicht unterstützt.")
    //     })
    //   });
    // } else { 
    // }
    const handleClick = (e) => {
      e.preventDefault()
      // Here I use a out of box hidden text area to copy it
      const textArea = document.createElement("textarea")
      textArea.value = window.location.origin + links.getAttribute("href");

      textArea.style.position = "absolute";
      textArea.style.visibility = "invisible"

      document.body.prepend(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        var checkmark = document.createElement("span");
        checkmark.innerHTML = "&#10003;";
        checkmark.style.paddingLeft = "10px"
        links.appendChild(checkmark);
        setTimeout(function(){
          links.removeChild(checkmark);
        }, 2000);
      } catch (error){
        console.error(error);
        alert("Das Kopieren in die Zwischenablage wird von Ihrem Browser nicht unterstützt.")
      } finally {
        textArea.remove()
      }
    }
    links.addEventListener("click", handleClick)
    links.addEventListener("touchstart", handleClick)
  })
}

function copyToClipboardHtmx(){
  const tabletBreakpoint = 960
  if (window.innerWidth <= tabletBreakpoint){
    document.addEventListener('htmx:afterOnLoad', copyToClipboard)
  }
}