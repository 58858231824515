/**
 * @param {HTMLElement} el
 */

const closeManualBtn = document.querySelectorAll("[data-close-manual]");
const manualModal = document.querySelector("[data-manual]");

function openModal(name) {
  // close all others
  Array.from(document.querySelector("[data-modal]"))
    .filter((m) => m.hasAttribute("[data-modal-open]"))
    .map((el) => el.getAttribute("data-modal"))
    .filter((name) => !!name)
    .forEach((name) => closeModal(name, force));

  const modal = document.querySelector(`[data-modal=${name}]`);


  modal.setAttribute("data-modal-open", "");
  document.body.scrollTo({ top: 0, behavior: "smooth" });
  document.querySelector("body").style.overflow = "hidden";
  modal.style.display = "block";
  return true;
}

function closeModal(name) {
  const modal = document.querySelector(`[data-modal=${name}]`);
  modal.removeAttribute("data-modal-open");
  document.querySelector("body").style.overflow = "unset";
  modal.style.display = "none";
  return true;
}
/**
 * @param {HTMLElement} el
 */
function openModalButtons(el) {
  const modalName = el.getAttribute("data-open-modal");

  if (!modalName) {
    return;
  }
  el.addEventListener("click", () => {
    openModal(modalName);
  });
}

function closeModalButtons(el) {
  const modalName = el.getAttribute("data-close-modal");

  if (!modalName) {
    return;
  }

  el.addEventListener("click", (e) => {
    e.preventDefault()
    closeModal(modalName);
  });
}

function closeManual(){
  closeManualBtn.forEach((cmb) => {
    cmb.addEventListener("click", () => {
        manualModal.style.display = "none";
        document.querySelector("body").style.overflow = "unset"
    })
  })
}

function SetCookieForModal() {
  // If there is no modalShown cookie
  const currentURL = window.location.href;
  const path = new URL(currentURL).pathname;
  const trimmedPath = path.replace("/model-preview/", "");
  let now = new Date();
  // Duration = 24 Hours for none User
  let cookieDurationForNonUser = 24 * 60 * 60 * 1000
  // Duration = 1 Year for User
  let cookieDurationForUser = 8760 * 60 * 60 * 1000
  let expireDateNotRegistered = new Date(now.getTime() + cookieDurationForNonUser);
  let expireDateRegistered = new Date(now.getTime() + cookieDurationForUser);
  const main = document.querySelector("[data-first-parent='model-viewer-content']");
  const body = main?.parentElement.parentElement;
  const sideMenuOpenAr = document.getElementById("side-menu-open-ar")
  const sideMenuIntro = document.getElementById("side-menu-intro")
  
  if(!body){
    return
  }

  if(!main){
    return
  }

  if (trimmedPath !== ""){
    sideMenuIntro.remove()
    if (document.cookie.indexOf("modalArInfo=true") == -1){
      manualModal.style.display = "block";
      fetch("/api/check-authentication/")
      .then(response => response.json())
      .then(data => {
        if (data.authenticated){
          document.cookie = "modalArInfo=true; expires=" + expireDateRegistered.toUTCString() + "; path=/";
        } else {
          document.cookie = "modalArInfo=true; expires=" + expireDateNotRegistered.toUTCString() + "; path=/";
        }
      })
      .catch(error => console.error(error))
    }
  }else{
    sideMenuOpenAr.remove()
    if (document.cookie.indexOf("modalShown=true") == -1){
      body.style.overflow = "hidden"
      manualModal.style.display = "block";
      // you can find this URL in the url.py
      fetch("/api/check-authentication/")
      .then(response => response.json())
      .then(data => {
        if (data.authenticated){
          document.cookie = "modalShown=true; expires=" + expireDateRegistered.toUTCString() + "; path=/";
          document.cookie = "modalArInfo=true; expires=" + expireDateRegistered.toUTCString() + "; path=/";
        } else {
          document.cookie = "modalShown=true; expires=" + expireDateNotRegistered.toUTCString() + "; path=/";
          document.cookie = "modalArInfo=true; expires=" + expireDateNotRegistered.toUTCString() + "; path=/";
        }
      })
      .catch(error => console.error(error))
    }
  }
}

window.addEventListener("load", () => {
  document.querySelectorAll("[data-open-modal]").forEach(openModalButtons);
  document.querySelectorAll("[data-close-modal]").forEach(closeModalButtons);
  closeManual();
  SetCookieForModal();
});

let downloadListBtn = document.querySelector("[data-open-download-list]");
let downloadListItems = document.querySelector("[data-download-list]");

if (!downloadListBtn){
  return
}

downloadListBtn.addEventListener("click", () => {
  console.log("hallo")
  downloadListItems.classList.toggle("open-download-files-items");
})