window.addEventListener("load", () => {
  const usdzFile = document.querySelector("[data-download-item='usdz']")
  const gltfFile = document.querySelector("[data-download-item='gltf']")
  const allFiles = document.querySelector("[data-download-item='all']")
  const downloadFilesBtn = document.querySelector("[data-focus='transparent-btn']")

  if(!allFiles){
    return
  }

  if(!downloadFilesBtn){
    return
  }

  // This is necessary because the focus of the button needs to disappear if dropdown is closed
  downloadFilesBtn.addEventListener("click", () => {
    downloadFilesBtn.classList.toggle("focused-light-transparent-btn")
  })

  allFiles.addEventListener("click", () => {
    usdzFile.click();
    gltfFile.click();
  })
})