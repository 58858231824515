window.addEventListener("load", () => {
  uploadLottie = document.getElementById("model-upload-form");
  converteranimation = document.getElementById("converter-animation");
  converterBtn = document.querySelector("[data-add-model='button']")
  
  if (!uploadLottie) {
    return;
  }



  uploadLottie.addEventListener("submit", () => {
    converteranimation.play();
    converteranimation.setLooping(true);
    changeTextConverting("Einen Moment bitte", "3D-Modell wird in die notwendigen Dateiformate konvertiert");
  });

  function changeTextConverting(header, paragraph){
    converterHeader = document.querySelector("[data-add-model='h2']");
    converterParagraph = document.querySelectorAll("[data-add-model='p']");
  
    converterHeader.innerHTML = header
  
    converterParagraph.forEach(element => {
      element.innerHTML = paragraph
    });
  
    converterBtn.style.display = "none"
  }
});
