(function () {
  const dragAreas = document.querySelectorAll("[data-drop]");

  dragAreas.forEach((dragDrop) => {
    const formSelector = dragDrop.getAttribute("data-drop");
    const form = document.querySelector(formSelector);

    const inputs = form.querySelectorAll('input[type="file"]');

    inputs.forEach((input) => {
      input.addEventListener("change", (e) => {
        form.requestSubmit();
      });
    });

    dragDrop.addEventListener("dragover", function dragOver(e) {
      console.log("File in drop zone");
      e.preventDefault();
    });
    
    dragDrop.addEventListener("drop", function dropHandler(e) {
      console.log("File dropped");
      e.preventDefault();

      inputs.forEach((input) => {
        input.files =  e.dataTransfer.files;
      });

      form.requestSubmit();
    });
  });
})();


