import QRCode from "qrcode";

function initQrCodes() {
  const qrCodes = document.querySelectorAll("[data-qr-code]");

  qrCodes.forEach((qrCode) => {
    if (qrCode.hasAttribute("data-qr-code-init")) {
      return;
    }
    const path = qrCode.getAttribute("data-qr-code");

    QRCode.toDataURL(
      `${window.location.origin}${path}`,
      {
        errorCorrectionLevel: "H",
        type: "image/jpeg",
        quality: 1,
        color: {
          dark: "#ff00ff",
          light: "#fff",
        },
      },
      function (err, url) {
        if (err) throw err;

        qrCode.setAttribute("data-qr-code-init", "true");

        const img = qrCode.appendChild(document.createElement("img"));
        img.src = url;
        img.alt = "Model QR Code";
      }
    );
  });
}
window.addEventListener("load", initQrCodes);
document.addEventListener("htmx:afterOnLoad", initQrCodes);
